
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
            <h1 class="my-4"> {{$store.getters.language.data.incurrences_take_outs.title}}</h1>

			<!-- {{incurrences_take_outs.user_id}} -->
			<form @submit.prevent="addIncurrencesTakeOuts" autocomplete="off">
				<v-layout row wrap>

                    <v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="incurrences_take_outs.incurrences_take_out_amount" type="text" :label="$store.getters.language.data.incurrences_take_outs.incurrences_take_out_amount" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="incurrences_take_outs.incurrences_take_out_date" type="datetime-local" :label="$store.getters.language.data.incurrences_take_outs.incurrences_take_out_date" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex> -->

                    <v-flex xs12 lg4 xl4 md6 sm6>
						<v-autocomplete class="mx-1" clearable :items="employees" v-model="incurrences_take_outs.employee_id" dense filled outlined item-text="employee_name" item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_name">
						</v-autocomplete>
					</v-flex>

                    <v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="user" v-model="incurrences_take_outs.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.incurrences_take_outs.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" :label="$store.getters.language.data.extra.search" append-icon="mdi-magnify" outlined></v-text-field>
					<v-card>

						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="incurrences_take_out_id">
								<!-- <template v-slot:[`item.incurrences_take_out_id`]="{ item }">
									<div>
										<v-btn icon :to="'/incurrences_take_outs-list/'+item.incurrences_take_out_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectIncurrencesTakeOuts(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template> -->
                                <template v-slot:[`item.incurrences_take_out_amount`]="{ item }">
                                    <div>
                                        {{(item.incurrences_take_out_amount).toLocaleString()}}
                                    </div>
                                </template>
                                <template v-slot:[`item.incurrences_take_out_date`]="{ item }">
                                    <div>
                                        {{new Date(item.incurrences_take_out_date).toISOString().split('T')[0]}}
                                    </div>
                                </template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteIncurrencesTakeOutsList">{{$store.getters.language.data.incurrences_take_outs.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.incurrences_take_outs.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.incurrences_take_outs.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteIncurrencesTakeOuts(selected_incurrences_take_outs.incurrences_take_out_id)">
						{{$store.getters.language.data.incurrences_take_outs.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/incurrences_take_outs.request.js'
	import requestsEmployee from './../../requests/employees.request.js'
	export default {
		data() {
			return {
				incurrences_take_outs: {
					user_id: '',
				},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_incurrences_take_outs: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.incurrences_take_outs.incurrences_take_out_amount,
						align: 'start',
						sortable: true,
						value: 'incurrences_take_out_amount',
					},
					{
						text: this.$store.getters.language.data.incurrences_take_outs.incurrences_take_out_date,
						align: 'start',
						sortable: true,
						value: 'incurrences_take_out_date',
					},
					{
						text: this.$store.getters.language.data.employees.employee_name,
						align: 'start',
						sortable: true,
						value: 'employee_name',
					},
					{
						text: this.$store.getters.language.data.users.user_name,
						align: 'start',
						sortable: true,
						value: 'user_name',
					}
                    // , 
                    // {
					// 	text: this.$store.getters.language.data.actions,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'incurrences_take_out_id',
					// }
				],
			}
		},
		computed: {
			// employees(){
			//     return this.$store.getters.employees_list
			// },
			// users(){
			// return this.$store.getters.users_list
			// },
			user() {
				return this.$store.getters.user
			}
		},
		mounted() {
			this.readIncurrencesTakeOuts();
			this.readAllEmployees();

		},
		methods: {
			readAllEmployees() {
				this.loading = true
				requestsEmployee.getAllEmployees().then(r => {
					if (r.status == 200) {
						this.employees = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})

			},
			addIncurrencesTakeOuts() {
				this.loading_btn = true
				requests.createIncurrencesTakeOuts(this.incurrences_take_outs).then(r => {
					if (r.status == 200) {
						this.incurrences_take_outs = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'IncurrencesTakeOuts Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add IncurrencesTakeOuts',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteIncurrencesTakeOuts(incurrences_take_out_id) {
				requests.deleteIncurrencesTakeOuts(incurrences_take_out_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.incurrences_take_out_id != incurrences_take_out_id
						})
						this.snackbar = {
							value: true,
							text: 'IncurrencesTakeOuts Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteIncurrencesTakeOutsList() {
				let ids = this.selected_rows.map(m => m.incurrences_take_out_id)
				requests.deleteIncurrencesTakeOutsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.incurrences_take_out_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' IncurrencesTakeOuts Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readIncurrencesTakeOuts() {
				this.loading = true
				requests.getAllIncurrencesTakeOuts().then(r => {

					if (r.status == 200) {
						this.incurrences_take_outs.user_id = this.user.user_id
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read IncurrencesTakeOuts',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read IncurrencesTakeOuts',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectIncurrencesTakeOuts(i) {
				this.selected_incurrences_take_outs = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    